import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import api from "@services/api";
import Swal from "sweetalert2";

interface EmmitedReceiptButtonsProps {
  receiptId: string | number;
  clientId: string | number;
  receipts: any[];
  deleted: boolean;
  onDelete?: Function,
  isActive: boolean;
};

export default function EmmitedReceiptButtons({
  receiptId,
  clientId,
  receipts,
  deleted,
  onDelete,
  isActive,
}: EmmitedReceiptButtonsProps) {
  const history = useHistory();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => {
            history.push(
              `/emitreceipt/client/${clientId}/receipt/${receiptId}`
            );
          }}
        >
          {isActive ? "Ver Recibo" : "Ver Recibo Cancelado"}
        </Button>
      </Grid>
      {onDelete ? (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            disabled={deleted}
            onClick={() => {
              Swal.fire({
                icon: 'warning',
                title: "Deseja cancelar esse recibo?",
                text: "Esta ação não pode ser desfeita",
                input: "text",
                inputAttributes: {
                  autocapitalize: "off",
                  placeholder: "Informe o motivo do cancelamento",
                },
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                reverseButtons: true,
                preConfirm: (text) => {
                  api
                    .delete(`/receipts/${receiptId}`, {
                      data: {
                        cancellationReason: text,
                      }
                    })
                    .then(() => {
                      const newReceipts = receipts.map((receipt: any) => {
                        if (receipt.id === receiptId)
                          receipt.deletedAt = new Date().toISOString();
                        return receipt;
                      });
                      if (onDelete instanceof Function) onDelete(newReceipts);
                      history.push(`/emitreceipt/client/${clientId}/receipt/${receiptId}`);
                    })
                    .catch((error) => {
                      Swal.showValidationMessage(`Request failed: ${error}`);
                    });
                },
                allowOutsideClick: () => !Swal.isLoading(),
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire({
                    icon: "success",
                    title: "Recibo cancelado com sucesso",
                    showConfirmButton: false,
                    timer: 2500,
                  });
                }
              });
            }}
          >
            {!deleted && "Cancelar"}
            {deleted && "Cancelado"}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}
