import React from "react";
import { Grid, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchIcon: {
    paddingRight: theme.spacing(2),
  },
}));

interface ListClientsFiltersProps {
  setClients: Function;
  clients: any;
};

export default function ListClientsFilters({ setClients, clients }: ListClientsFiltersProps) {
  const classes = useStyles();

  function handleChange(event: any) {
    if (event.target.value === '') {
      setClients(clients);
      return;
    }

    const searchValue = sanitizeString(event.target.value);

    function sanitizeString(str: string) {
      // Remove accentuation and put strings to lower case
      return str
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    }

    function sanitizeCPF(str: string) {
      return str.replace(/[^0-9]/g, "");
    }

    function verifyCPF() {
      const sanitizedString = sanitizeCPF(searchValue);
      return sanitizedString.length > 0;
    }

    function findClientByName(searchValue: string, name: string) {
      return sanitizeString(name).indexOf(searchValue) > -1;
    }

    function findClientByCPF(searchValue: string, cpf: string) {
      const sanitizedValue = sanitizeCPF(searchValue);
      return (
        sanitizeCPF(cpf)
          .replace(".", "")
          .replace("-", "")
          .indexOf(sanitizedValue) > -1
      );
    }


    const listaNames = clients.filter((client: any) =>
      findClientByName(searchValue, `${client.firstName} ${client.surname}`)
    );
    const listaCPFs = clients.filter((client: any) =>
      findClientByCPF(searchValue, client.cpf)
    );

    const isCPF = verifyCPF();

    const result = [...(isCPF ? listaCPFs : listaNames)];

    setClients(result);
  }

  return (
    <Grid item md={10} sm={9} xs={12}>
      <div className={classes.searchContainer}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <TextField
          label="CPF/Nome"
          id="search-clients"
          name="search-clients"
          variant="outlined"
          fullWidth
          onChange={(event) => handleChange(event)}
          size="small"
        />
      </div>
    </Grid>
  );
}
