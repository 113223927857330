import { Fab, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  Link,
  useLocation
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(4),
  },
}));

export default function FabButton() {
  const classes = useStyles();
  const location = useLocation()

  return location.pathname !== '/clientlist' ? (
    <Link to="/clientlist">
      <Fab
        color="primary"
        className={classes.fab}
        aria-label="add"
      >
        <AddIcon />
      </Fab>
    </Link>
  ):null;
}
