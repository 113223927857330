import { ComponentProps } from 'react';
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Typography,
    CardActions
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[700]
    },
    pricing: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        marginBottom: theme.spacing(2)
    },
    children: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

interface PlansCardProps extends ComponentProps<any> {
    name: string;
    subheader: string;
    amount: string;
    description: string;
};

export default function PlansCard({
    name,
    subheader,
    amount,
    description,
    children
}: PlansCardProps) {
    const classes = useStyles();

    return (
        <Grid
            item
            xs={12}
        >
            <Card>
                <CardHeader
                    title={name}
                    subheader={subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.header}
                />
                <CardContent>
                    <div className={classes.pricing}>
                        <Typography
                            component="h2"
                            variant="h3"
                            color="textPrimary"
                        >
                            R{amount.replace(".", ",")}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                            /mês
                        </Typography>
                    </div>
                    <ul>
                        <Typography
                            component="li"
                            variant="subtitle1"
                            align="center"
                            style={{ fontSize: 16 }}
                        >
                            {description}
                        </Typography>
                    </ul>
                </CardContent>
                <CardActions>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid
                            item
                            xs={12}
                            className={classes.children}
                        >
                            {children}
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    );
};