import type { ComponentProps } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';

interface StyledLinkProps extends ComponentProps<typeof Link> {
	externalLink?: boolean;
};

export default function StyledLink({ href, onClick, externalLink, ...props }: StyledLinkProps) {
	const history = useHistory();

	return (
		<Link
			{...props}
			href={externalLink ? href : undefined}
			onClick={externalLink ?
				undefined :
				event => {
					history.push(href || '#');
					if (onClick instanceof Function)
						onClick(event);
				}
			}
			target={externalLink ? '_blank' : undefined}
		/>
	);
}
