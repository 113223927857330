import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import BarChartIcon from "@material-ui/icons/BarChart";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import HelpIcon from '@material-ui/icons/Help';
import { Link } from "react-router-dom";
import { AppContext } from "@providers/app";
import { useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@providers/auth';

interface Item {
  icon: any;
  primary: string;
  to: string;
  admin?: boolean;
  variantPath?: string | string[];
  externalLink?: boolean;
};

const arrayItensMenu: Item[] = [
  {
    icon: <SupervisedUserCircleIcon />,
    primary: "Administrador",
    to: '/admin',
    admin: true
  },
  {
    icon: <AccountCircleIcon />,
    primary: "Meu Perfil",
    to: "/myprofile"
  },
  {
    icon: <BarChartIcon />,
    primary: "Visão Geral",
    to: "/",
  },
  {
    icon: <ShoppingCartIcon />,
    primary: "Emitir Recibo",
    to: "/clientlist",
    variantPath: [
      "/clientdata",
      "/emitreceipt"
    ]
  },
  {
    icon: <DashboardIcon />,
    primary: "Recibos Emitidos",
    to: "/emittedreceipts",
  },
  {
    icon: <WhatshotIcon />,
    primary: "Seja Premium",
    to: "/pricing"
  },
  {
    icon: <HelpIcon />,
    primary: "Suporte",
    to: "https://visupsi.com.br/suporte",
    externalLink: true
  }
];

export function MainListItems() {
  const theme = useTheme()
  const { user } = useAuth();
  const location = useLocation();
  const {
    openMenu: [open, setOpen]
  } = React.useContext(AppContext);

  function ListItemLink(props: Item) {
    const { icon, primary, to, variantPath, externalLink } = props;

    const CustomLink = React.useMemo(
      () =>
        React.forwardRef((linkProps: any, ref: any) => externalLink ?
          (
            <a
              ref={ref}
              href={to}
              target="_blank"
              rel='noreferrer'
              {...linkProps}
            >
              {linkProps.children}
            </a>
          ) :
          (
            <Link ref={ref} to={to} {...linkProps} />
          )),
      [externalLink, to]
    );

    function checkSelected() {
      if (location.pathname === to)
        return true;

      if (typeof (variantPath) === 'string') {
        return location.pathname.indexOf(variantPath) >= 0;
      } else if (variantPath instanceof Array) {
        for (let path of variantPath) {
          if (location.pathname.indexOf(path) >= 0)
            return true;
        }
      }

      return false;
    }

    return (
      <li>
        <ListItem
          button
          component={CustomLink}
          onClick={() => {
            const isOpen = open;
            if (isOpen) setOpen(false);
          }}
          style={{
            color: checkSelected() ? 'white' : 'black',
            backgroundColor:
              //@ts-ignore
              checkSelected() ? theme.menuItemSelected.color :
                "none",
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  }

  return (
    <div>
      {arrayItensMenu.map((item, index) => (!item.admin || user.admin) && (
        <ListItemLink
          key={index}
          {...item}
        />
      ))}
    </div>
  );
}
