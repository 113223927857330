import React, { ComponentProps } from "react";
import clsx from "clsx";
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  Button,
  Container
} from "@material-ui/core";
import { AppContext } from "@providers/app";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { MainListItems } from "./ListItems";
import logoWhite from "@assets/logoWhite.png";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "@providers/auth";
import PlainViewer from "./PlainViewer";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.primary.main,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["padding", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
    color: "white",
    display: "flex",
  },
  drawerPaper: {
    position: "absolute",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: ".5px .5px .5px .5px #00000017",
  },
  drawerPaperClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9) + 1,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4) + 50,
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  menu: {
    "&& .MuiTypography-root": {
      fontSize: "calc(11px + 0.7vmin)",
    },
  },
  logo: {
    height: 30,
    marginRight: 20,
    marginLeft: -30
  },
}));

export default function ContainerWithMenu({ children }: ComponentProps<any>) {
  const classes = useStyles();
  const history = useHistory();
  const {
    openMenu: [open, setOpen],
  } = React.useContext(AppContext);
  const { SignOut } = useAuth();
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    const isOpen = open;
    setOpen(!isOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            className={clsx(classes.menuButton)}
          >
            {open ? (
              <ChevronLeftIcon color="inherit" />
            ) : (
              <MenuIcon color="inherit" />
            )}
          </IconButton>
          <Link className={classes.title} to="/">
            <img className={classes.logo} src={logoWhite} alt="logo" />
          </Link>
          <IconButton color="inherit">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                SignOut();
                history.push("/");
              }}
            >
              Sair
            </Button>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={"temporary"}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        className={classes.menu}
        open={open}
        onClose={handleDrawerClose}
      >
        <div className={classes.toolbarIcon}>
          <img className={classes.logo} src={logoWhite} alt="logo" />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <Divider />
        <List>
          <PlainViewer onRedirect={handleDrawerClose} />
          <MainListItems />
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
}
