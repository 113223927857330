import { ComponentProps } from 'react';
import {
  Grid,
  TextField,
  Button
} from '@material-ui/core';
import ModalStyled from './ModalStyled';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cpfMask from '@utilities/cpfMask';
import api from '@services/api';
import { toast, ToastOptions } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  container: {
    width: '88%'
  },
  form: {
    height: '55vh',
    overflowY: 'auto',
    marginBottom: '1vh'
  },
  buttons: {
    height: '16vh'
  }
}));

interface ClientModalEditProps extends ComponentProps<any> {
  show: boolean;
  client: any;
  editFeedback?: Function;
};

export default function ClientModalEdit({
  show,
  client,
  editFeedback,
  ...props
}: ClientModalEditProps) {
  const classes = useStyles();

  async function updateClient() {
    const toastConfigs: ToastOptions = { position: 'bottom-right' };
    const form = (document.forms as any).updateClient;

    const dataToSend = {
      firstName: form.firstName.value,
      surname: form.surname.value,
      cpf: form.cpf.value,
      email: form.email.value,
      obs: form.obs.value
    };

    try {
      await api.post(`/clients/${client.id}`, dataToSend);

      if (editFeedback instanceof Function)
        await editFeedback();

      if (props.onClose instanceof Function)
        props.onClose();

      toast.success('Cliente atualizado com sucesso', toastConfigs);
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar o cliente', toastConfigs)
    }
  }

  return (
    <ModalStyled
      {...props}
      show={show}
      title={`#${client.id} - ${client.firstName}`}
    >
      <div className={classes.container}>
        <Grid
          container
          spacing={3}
          className={classes.form}
          component='form'
          name='updateClient'
        >
          <Grid item xs={12} md={6}>
            <TextField
              required
              variant='outlined'
              defaultValue={client.firstName}
              label='Nome'
              name='firstName'
              placeholder='Insira o nome do cliente'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              variant='outlined'
              defaultValue={client.surname}
              name='surname'
              label='Sobrenome'
              placeholder='Insira o sobrenome do cliente'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant='outlined'
              defaultValue={client.cpf}
              name='cpf'
              label='CPF'
              onChange={(event) => cpfMask(event)}
              placeholder='Insira o CPF do cliente'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant='outlined'
              type='email'
              defaultValue={client.email}
              name='email'
              label='E-mail'
              placeholder='Insira o E-mail do cliente'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant='outlined'
              name='obs'
              defaultValue={client.obs}
              label='Observações'
              placeholder='Insira as observações do cliente'
              fullWidth
              multiline
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className={classes.buttons}
          alignItems='center'
          justifyContent='center'
        >
          <Grid item xs={12} sm={6}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => updateClient()}
            >
              Enviar
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant='contained'
              color='secondary'
              fullWidth
              onClick={() => {
                if (props.onClose instanceof Function)
                  props.onClose();
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </div>
    </ModalStyled>
  );
}
