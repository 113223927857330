function cpfMask(event: any, setCpf?: Function){
  event.preventDefault()

  const target = event.target;
  const value = target.value;
  const cpfFiltered = value.replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
  if(setCpf instanceof Function)
    setCpf(cpfFiltered)
  else
    target.value = cpfFiltered
}

export default cpfMask;
