import {
	Grid,
	Button
} from '@material-ui/core';

interface AddClientProps {
	onAdd: Function;
};

export default function AddClient({ onAdd }: AddClientProps) {
	return (
		<Grid
			item
			md={2}
			sm={3}
			xs={12}
		>
			<Button
				variant="contained"
				color="primary"
				fullWidth
				onClick={event => {
					if (onAdd instanceof Function)
						onAdd(event)
				}}
			>
				Novo Cliente
			</Button>
		</Grid>
	);
}