import React, { ComponentProps } from 'react';
import {
    Dialog,
    Button,
    Grid,
    Typography,
    TextField
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import api from '@services/api';
import Swal from 'sweetalert2';
import { useAuth } from '@providers/auth';
import { format } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';

const useStyles = makeStyles(theme => ({
    root: {
        '&& .MuiButton-contained': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main
        },
        '&& .MuiButton-contained:hover': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.dark
        },
        '&& .Mui-disabled': {
            boxShadow: 'none',
            color: 'rgba(0, 0, 0, 0.26)',
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
        }
    }
}));

interface ModalCancelProps extends ComponentProps<any> {
    onClose: Function;
    open: boolean;
}

export default function ModalCancel({ onClose, ...props }: ModalCancelProps) {
    const [confirmationText, setConfirmationText] = React.useState('');
    const classes = useStyles();
    const confirmed = confirmationText === 'CONFIRMAR CANCELAMENTO';
    const { user, setUser } = useAuth();
    const dateToCancel = new Date();
    dateToCancel.setMonth(dateToCancel.getMonth() + 1);
    const cancelDateString = format(dateToCancel, 'dd/MM', { locale });

    async function cancelPlan() {
        if (onClose instanceof Function)
            onClose();

        try {
            const { data } = await api.delete(`/stripe/cancel/${user.stripeSubscriptionId}`);

            setUser(data.user);

            Swal.fire('Cancelado', "Seu plano foi cancelado", 'success');
        } catch (error) {
            Swal.fire('Erro!', 'Houve um erro ao tentar cancelar o plano', 'error');
        }
    }

    return (
        <Dialog
            {...props}
            onClose={() => {
                setConfirmationText('');
                if (onClose instanceof Function)
                    onClose();
            }}
        >
            <div style={{
                padding: 20,
                width: '100%'
            }}>
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            Deseja realmente cancelar seu plano?
                        </Typography>
                        <Typography variant="caption">
                            Cancelando o plano você passa a ter um limite de recibos novamente.
                            Além disso,
                            cancelando sua assinatura,
                            seu plano premium só estará válido até o dia {cancelDateString}.
                            após esse dia,
                            só será revalidado quando reativá-lo novamente por meio de um novo pagamento.
                            Para confirmar o cancelamento,
                            digite abaixo <b>"CONFIRMAR CANCELAMENTO"</b> sem aspas e clique em "Cancelar Plano".
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="filled"
                            error
                            name="confirmation"
                            id="confirmation"
                            onChange={event => setConfirmationText(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled={!confirmed}
                            fullWidth
                            variant="contained"
                            onClick={cancelPlan}
                        >
                            Cancelar plano
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
}