import createTheme, { ThemeOptions } from '@material-ui/core/styles/createTheme';
import { orange } from '@material-ui/core/colors';

interface ThemeOptionsMod extends ThemeOptions {
	menuItemSelected: {
		color: string;
		text: string;
	}	
}

const theme: ThemeOptionsMod = {
	palette: {
		primary: {
			main: "#9100d2"
		},
		secondary: {
			main: orange[700],
			contrastText: 'white'
		}
	},
	menuItemSelected: {
		color: orange[700],
		text: 'white'
	}
};

const root = createTheme(theme);

export default root;
