import { ComponentProps } from 'react';
import {
	IconButton,
	Typography,
	Modal,
	Paper,
	Backdrop,
	Fade
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modalContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		height: '80vh',
		width: '80vw',
		'&& *': {
			fontSize: 'calc(9px + 0.7vmin)'
		},
		'&& .MuiTypography-h6': {
			fontSize: 'calc(14px + 0.7vmin)'
		},
		'&& .MuiFormLabel-root': {
			fontSize: 'calc(12px + 0.7vmin)'
		},
		'&& .MuiTableCell-sizeSmall': {
			padding: '6px 6px 6px 8px'
		},
		'&& .MuiTable-root': {
			tableLayout: 'fixed',
			wordWrap: 'break-word',
			borderCollapse: 'collapse'
		},
		'&& .MuiSvgIcon-root': {
			fontSize: 'calc(15px + 0.7vmin)'
		}
	},
	closeButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '95%'
	},
	closeButton: {
		marginBottom: '-6vh',
	},
	title: {
		margin: '1vh'
	}
}))

interface ModalStyledProps extends ComponentProps<any> {
	show: boolean;
	title: string;
};

export default function ModalStyled({
	show,
	title,
	children,
	...props
}: ModalStyledProps){
	const classes = useStyles()

	return(
		<Modal
			{...props}
			open={show}
			className={classes.modal}
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={show}>
				<Paper className={classes.modalContainer}>
					<div className={classes.closeButtonContainer}>
						<IconButton
							color="secondary"
							className={classes.closeButton}
							onClick={props.onClose}
						>
							<CloseIcon/>
						</IconButton>
					</div>
					<Typography
						variant="h6"
						className={classes.title}
					>
						{title}
					</Typography>
					{children}
				</Paper>
			</Fade>
		</Modal>
	)
}
