type StringKeysObject<T> = {
	[key: string]: T 
}

export default function getGridScale(){
	const width = window.innerWidth;

	const scales = {
		'xs': (width <= 576),
		'sm': (width > 576 && width <= 768),
		'md': (width > 768 && width <= 992),
		'lg': (width > 992 && width <= 1200),
		'xl': (width > 1200 && width <= 1400),
		'xxl': (width > 1400)
	}

	for(let scale in scales){
		if((scales as StringKeysObject<boolean>)[scale] === true)
			return scale
	}
}