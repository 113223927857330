import React from 'react';
import {
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
    Divider
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useAuth } from '@providers/auth';
import api from '@services/api';
import StyledLink from './StyledLink';
import type { MouseEventHandler } from 'react';

const useStyles = makeStyles(theme => ({
    text: {
        color: theme.palette.success.dark
    },
    icon: {
        color: theme.palette.success.dark
    },
    subheader: {
        marginTop: theme.spacing(-1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        fontSize: 'calc(6px + 0.7vmin) !important',
        whiteSpace: 'normal'
    },
    link: {
        fontSize: 'calc(6px + 0.7vmin) !important',
        margin: 0
    }
}));

interface PlainViewerProps {
    onRedirect?: MouseEventHandler;
};

export default function PlainViewer({ onRedirect }: PlainViewerProps) {
    const { user, setUser } = useAuth();
    const classes = useStyles();
    const plains = ['Free', 'Premium'];
    const freeReceipts = user.freeReceiptsInActualMonth;

    React.useEffect(() => {
        async function onLoad() {
            try {
                const request = await api.get('/users/me');
                const user = request.data;
                setUser(user);
            } catch (error) {
                return;
            }
        }
        onLoad();
    }, [setUser])

    return (
        <React.Fragment>
            <ListItem>
                <ListItemIcon>
                    <MonetizationOnIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.text}>
                    <b>
                        VisuPsi {plains[user.plain] || plains[0]}
                    </b>
                </ListItemText>
            </ListItem>
            {user.plain === 0 &&
                <Typography
                    className={classes.subheader}
                    color="primary"
                >
                    {freeReceipts < 3 ?
                        <React.Fragment>
                            Você tem {3 - freeReceipts} recibos restantes
                        </React.Fragment> :
                        <React.Fragment>
                            Seus recibos disponíveis acabaram.{' '}
                            Para continuar, atualize para o plano{' '}
                            <span>
                                <StyledLink
                                    className={classes.link}
                                    href='/pricing'
                                    onClick={onRedirect}
                                >
                                    Premium
                                </StyledLink>
                            </span>
                        </React.Fragment>}
                </Typography>}
            <Divider />
        </React.Fragment>
    );
}