import { ComponentProps, createContext, useState, useContext, useEffect } from "react";
import api from "@services/api";
import { toast } from "react-toastify";

interface AuthContextInterface {
  signed: boolean | null;
  loading: boolean;
  user: any;
  SignIn: Function,
  SignOut: Function,
  setUser: Function
};

const INITIAL_CONTEXT = {
  signed: null,
  loading: true,
  user: {},
  SignIn: () => { },
  SignOut: () => { },
  setUser: () => { }
};

const AuthContext = createContext<AuthContextInterface>(INITIAL_CONTEXT);

export default function AuthProvider({ children }: ComponentProps<any>) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [signed, setSigned] = useState<boolean | null>(null);

  useEffect(() => {
    async function loadStorage() {
      const storageToken = localStorage.getItem("@VisuPsi:token");
      if (storageToken) {
        try {
          api.defaults.headers.Authorization = `Bearer ${storageToken}`;
          const { data } = await api.get("/users/me");
          setUser(data);
          setSigned(true);
        } catch (err) {
          SignOut();
        }
      }
      setLoading(false);
    }
    loadStorage();
  }, []);

  async function SignIn(data: any) {
    api
      .post("/auth/login", data)
      .then((res) => {
        localStorage.setItem("@VisuPsi:token", res.data.token);
        api.defaults.headers.Authorization = `Bearer ${res.data.token}`;

        setUser(res.data.user);
        setSigned(true);
      })
      .catch((err) => {
        toast.error("Verifique as suas credenciais!");
        setSigned(false);
      });
  }

  function SignOut() {
    setSigned(false);
    localStorage.removeItem("@VisuPsi:token");
  }

  return (
    <AuthContext.Provider
      value={{
        signed,
        user,
        setUser,
        loading,
        SignIn,
        SignOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
