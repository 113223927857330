import { lazy } from 'react';

const Dashboard = lazy(()=> import('./Dashboard'));
const Admin = lazy(()=> import('./Admin'));
const EmitReceipt = lazy(()=> import('./EmitReceipt'));
const EmittedReceipts = lazy(()=> import('./EmittedReceipts'));
const Login = lazy(()=> import('./Login'));
const Pricing = lazy(()=> import('./Pricing'));
const ReceiptDataForInvoice = lazy(()=> import('./ReceiptDataForInvoice'));
const RegisterClient = lazy(()=> import('./RegisterClient'));
const RegisterUser = lazy(()=> import('./RegisterUser'));
const ListClients = lazy(()=> import('./ListClients'));
const Profile = lazy(()=> import('./Profile'));
const Support = lazy(()=> import('./Support'));
const ResetPassword = lazy(()=> import('./ResetPassword'));
const NotFound = lazy(()=> import('./NotFound'));

export {
	NotFound,
	ResetPassword,
  	Admin,
	Support,
	Profile,
	ListClients,
	Dashboard,
	EmitReceipt,
	EmittedReceipts,
	Login,
	Pricing,
	ReceiptDataForInvoice,
	RegisterClient,
	RegisterUser
}
