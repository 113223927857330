import { Button, Grid } from "@material-ui/core";

interface ClientCardButtonGroupProps {
  onEmitReceipt?: Function;
  onInative?: Function;
  onEdit?: Function;
  onViewReceipts?: Function;
  client: any;
};

export default function ClientCardButtonGroup({
  onEmitReceipt,
  onInative,
  onEdit,
  onViewReceipts,
  client,
}: ClientCardButtonGroupProps) {
  return (
    <Grid container xs={12} spacing={1}>
      <Grid item xs={6}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={client.deletedAt}
          fullWidth
          onClick={() => {
            if (onEdit instanceof Function) onEdit();
          }}
        >
          Editar
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
		  disabled={client.deletedAt}
          size="small"
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => {
            if (onEmitReceipt instanceof Function) onEmitReceipt();
          }}
        >
          Emitir Recibo
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => {
            if (onViewReceipts instanceof Function) onViewReceipts();
          }}
        >
          Ver Recibos
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() => {
            if (onInative instanceof Function) onInative();
          }}
        >
          {client.deletedAt ? 'reativar' : 'inativar'}
        </Button>
      </Grid>
    </Grid>
  );
}
