import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading(){
	return(
		<div style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100vh'
		}}>
			<div style={{
				paddingRight: '30px'
			}}>
				Aguarde um momento...
			</div>
			<CircularProgress disableShrink/>
		</div>
	);
}