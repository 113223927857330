import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import StyledLink from './StyledLink';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function Footer() {
  const classes = useStyles();

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
          VisuPsi
        {" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          VisuPsi
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          Seus recibos na palma da sua mão.
        </Typography>
        <Copyright />
        <StyledLink
          href="https://visupsi.com.br/termos-de-uso"
          externalLink
          color="textSecondary"
        >
          Termos de uso
        </StyledLink>
        <StyledLink
          href="https://visupsi.com.br/politica-de-privacidade"
          externalLink
          color="textSecondary"
        >
          Política de privacidade
        </StyledLink>
      </footer>
    </React.Fragment>
  );
}
