import { ComponentProps } from 'react';
import { Grid, TextField } from '@material-ui/core';

interface RegisterClientInputProps extends ComponentProps<any> {
	name: string;
};

export default function RegisterClientInput({ name, ...props }: RegisterClientInputProps) {
	return (
		<Grid item xs={12}>
			<TextField
				id={name}
				name={name}
				fullWidth
				variant="outlined"
				{...props}
			/>
		</Grid>
	);
}
