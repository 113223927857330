import React from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppContext } from '@providers/app';

interface RenderPDFProps {
	file: any;
	page?: number;
	onLoadSuccess?: Function;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	xxl?: number;
};

export default function RenderPDf({
	file,
	page = 1,
	onLoadSuccess,
	xs = 1,
	sm = 1,
	md = 1,
	lg = 1,
	xl = 1,
	xxl = 1
}: RenderPDFProps){
	const {
		gridScale: [gridScale]
	} = React.useContext(AppContext);

	function setScale(){
		const scales = { xs , sm, md, lg, xl, xxl };

		return (scales as any)[gridScale];
	}

	function onDocumentLoadSuccess(){
		if(onLoadSuccess instanceof Function)
			onLoadSuccess();
	}

	const canvasRef = React.useRef(null);
	const { pdfDocument } = usePdf({
		file,
		page,
		canvasRef,
		onDocumentLoadSuccess,
		scale: setScale()
	});

	return(
		<React.Fragment>
			{!pdfDocument &&
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
			>
				<div
					style={{
						marginRight: '14px'
					}}
				>
					Carregando PDF...
				</div>
				<CircularProgress />
			</div>}
			{pdfDocument &&
			<canvas
				ref={canvasRef}
				style={{
					border: 'solid #0000003F 1px',
				}}
			/>}
		</React.Fragment>
	);
}