import React, { ComponentProps } from "react";
import getGridScale from '@utilities/getGridScale';

interface AppContextInterface {
  openMenu: any[],
  clients: any[],
  atendimentos: any[],
  gridScale: any[],
  theme: any[]
};

const initialContext: AppContextInterface = {
  openMenu: [],
  clients: [],
  atendimentos: [],
  gridScale: [],
  theme:[]
};

export const AppContext = React.createContext(initialContext);

export function AppProvider({ children }: ComponentProps<any>) {
  const appSettings = {
    openMenu: React.useState(false),
    clients: React.useState([]),
    atendimentos: React.useState([]),
    gridScale: React.useState(getGridScale()),
    theme: React.useState('root')
  };

  window.addEventListener('resize', ()=>{
    const setScale = appSettings.gridScale[1];
    const scale = getGridScale();

    setScale(scale);
  })

  return (
    <AppContext.Provider value={appSettings}>{children}</AppContext.Provider>
  );
}
