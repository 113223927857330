import React, { ComponentProps } from 'react';
import {
	Grid,
	Table,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
	Paper
} from '@material-ui/core';
import { ModalStyled } from '.';

interface ClientModalViewProps extends ComponentProps<any> {
	show: boolean;
	client: any;
};

export default function ClientModalView({
	show,
	client,
	...props
}: ClientModalViewProps) {
	const clientModel = {
		firstName: 'Nome',
		surname: 'Sobrenome',
		email: 'E-mail',
		cpf: 'CPF',
		obs: client.obs ? 'Detalhes' : undefined
	}

	return (
		<ModalStyled
			{...props}
			show={show}
			title={`#${client.id} - ${client.firstName}`}
		>
			<Grid container xs={9}>
				<Grid item xs={12}>
					<TableContainer component={Paper} variant="outlined">
						<Table size="small">
							<TableBody>
								{Object.keys(clientModel).map((field, key) => (
									<React.Fragment>
										{(clientModel as any)[field] &&
											<TableRow key={key}>
												<TableCell>
													<b>{(clientModel as any)[field]}</b>
												</TableCell>
												<TableCell>
													{client[field]}
												</TableCell>
											</TableRow>}
									</React.Fragment>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</ModalStyled>
	);
}
