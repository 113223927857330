import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { ClientCardButtonGroup } from ".";
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  title: {
    '&&:hover': {
      textDecoration: 'underline'
    },
    cursor: 'pointer',
    width: 'max-content'
  }
}));

interface ClientCardProps {
  client: any;
  onEmitReceipt?: Function;
  onView?: Function;
  onEdit?: Function;
  onViewReceipts?: Function;
  onInative?: Function;
};

export default function ClientCard({
  client,
  onEmitReceipt,
  onView,
  onEdit,
  onViewReceipts,
  onInative
}: ClientCardProps) {
  const classes = useStyles();
  const { firstName: name, email } = client;

  return (
    <Card style={{ backgroundColor: client.deletedAt ? "lightgray" : "white" }}>
      <CardContent>
        <Typography
          variant="h6"
          className={classes.title}
          onClick={() => {
            if (onView instanceof Function) onView(client);
          }}
        >
          {name}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {email}
        </Typography>
      </CardContent>
      <CardActions>
        <ClientCardButtonGroup
          client={client}
          onInative={() => {
            if (onInative instanceof Function) onInative(client);
          }}
          onEmitReceipt={() => {
            if (onEmitReceipt instanceof Function) onEmitReceipt(client);
          }}
          onEdit={() => {
            if (onEdit instanceof Function) onEdit(client);
          }}
          onViewReceipts={() => {
            if (onViewReceipts instanceof Function) onViewReceipts(client);
          }}
        />
      </CardActions>
    </Card>
  );
}
